@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color-primary: '#8d3848';
	--color-secondary: '#8d3848d9';
	--color-neutral-dark: '#ffffff';
	--color-neutral-light: '#b5b5b5';
	--color-accent: '#8d3848';
}


body {
	font-family: 'Segoe UI', Tahoma, sans-serif;
	overflow-x: hidden;
	word-break: break-word;
}
.MuiListItem-root{
	padding: 0;
}
.MuiTypography-root {
	font-family: 'Segoe UI', Tahoma, sans-serif;
}
.header-section{
	margin-top: 2em;
	margin-bottom: 2em;
	min-height: calc(100vh - 16rem);
	width: 90vw;
}

.section-header{
	margin-bottom: 3rem;
}

.section-large {
	width: 90vw;
	min-height: 50vh;
	margin-top: 2em;
	margin-bottom: 2em;
}

.section-medium {
	width: 90vw;
	min-height: 25vh;
	margin-top: 2em;
	margin-bottom: 2em;
}

.navbar,
.footer,
.footer-card {
	height: 5rem !important;
}

.items-centered {
	place-content: center;
}

main {
	min-height: calc(100vh - 10rem) !important;
}

.custom-dropdown-menu {
	margin-top: 1.1rem;
}

.text-centered {
	text-align: center;
}

.mobile-nav-item {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.MuiLink-root {
	color: #8d3848;
}

.grecaptcha-badge {
	visibility: hidden;
}

.centered-box {
	text-align: -webkit-center;
}
a:hover, button:hover{
	text-decoration: none !important;
	color: #616161;
}

a, .external-link{
	color: #8d3848 !important;
}

.overlay-text{
	font-size: 0.80rem !important;
}
@media (min-width: 1500px) {

	.overlay-text{
		font-size: 1rem !important;
	}
  }
.project-name-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.596); /* Semi-transparent black background */
    visibility: hidden; /* Hidden by default */
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear; /* Smooth transition for the overlay */
    padding: 10px;
}

a:hover .project-name-overlay {
    visibility: visible;
    opacity: 1;
}

header {
	padding: 0 !important;
	max-width: 90vw !important;
	margin: 0 auto;
}

.no-padding{
	padding: 0 !important;
}

.no-padding-x{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.no-margin{
	margin: 0 !important;
}

.no-margin-x{
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.MuiTimelineOppositeContent-root{
	text-align: left;
	max-width: 10rem;
	padding-left: 0;
	padding-right: 0;
}
.sendbutton {
	background: #8d3848;
	color: #fff;
}

.navbarbutton{
	margin-left: -1rem;
	margin-right: -1rem;
}

.text-navigation-text {
	font-size: 1.35rem;
  }
  
  @media (min-width: 1024px) {
	.text-navigation-text {
	  font-size: 1.6rem;
	}
  }
  